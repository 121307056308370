.dark-mode{
    background-color: darken($grisOscuro, 10%);

    p{
        color: $blanco;
    }
    a, h1, h2, h3, h4, h5, h6{
        color: $gris;
    }

    .anuncio, 
    .resumen-propiedad{
        background-color: $grisOscuro;
        border: 1px solid $grisOscuro;

        .precio{
            color: $verde;
        }
        .icono {
            filter: invert(100%);
        }
    }

    .texto-nosotros blockquote{
        color: $blanco;
    }

    .formulario{
        label,
        legend,
        p{
            color: $gris;
        }

        input:not([type="submit"]),
        textarea,
        select{
            background-color: $grisOscuro;
            border: 1px solid $grisOscuro;
            color: $blanco;
        }
    }
    
}