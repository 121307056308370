@include tablet{
    .contenedor-anuncios{
        @include grid(3, 2rem);
    }
}

.anuncio{
    background-color:lighten($gris, 5);
    border: 1px solid lighten($grisOscuro, 10);
    margin-bottom: 2rem;

    .contenido-anuncio{
        padding: 2rem;
    }

    h3,p{
        margin: 0;
    }

}

.precio{
    color: $verde;
    font-weight: $bold;
    font-size: 2.2rem;
}

.iconos-caracteristicas{
    list-style: none;
    padding: 0;
    display: flex;
    max-width: 40rem;
    li{
        flex: 1;
        display: flex;
        img{
            flex-shrink: 0;
            width: 3rem;
            margin-right: 1rem;
        }
        p{
            font-weight: $black;;
        }
    }
}