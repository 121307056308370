.contenido-nosotros{
    @include tablet{
        @include grid(2, 2rem);
    }
}

.texto-nosotros blockquote{
    font-weight: $black;
    font-size: 2.4rem;
    margin: 3rem 0 0 0;
    padding: 1rem 0 3rem 0;
}