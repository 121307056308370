.formulario{
    p{
        font-size: 1.4rem;
        color: $grisOscuro;
        margin: 2rem 0 0 0;
    }

    legend{
        font-size: 2.4rem;
        color: $grisOscuro;
    }

    label{
        font-weight: $bold;
        display: block;
        text-transform: uppercase;
    }

    input:not([type="submit"]), 
    textarea, select{
        padding: 1rem;
        display: block;
        width: 100%;
        background-color: $blanco;
        border: 1px solid $gris;
        border-radius: 1rem;
        margin-bottom: 2rem;
    }

    textarea{
        height: 200px;
    }

    .forma-contacto{
        max-width: 300px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    input[type="radio"]{
        width: auto;
        margin: 0;
    }
}