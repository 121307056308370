.footer{
    padding: calc($separacion / 2);
    background-color: $grisOscuro;
    margin-bottom: 0;

    .contenedor-footer{
        padding-top: calc($separacion / 2);
    }

    .navegacion{
        visibility: visible;
        opacity: 1;
        height: auto;
    }

    .copyright{
        color: $blanco;
        text-align: center;
    }
}