.seccion {
    margin-top: calc($separacion / 2);
    margin-bottom: calc($separacion / 2);
}
.contenido-centrado {
    max-width: 80rem;
}

.alinear-derecha{
    display: flex;
    justify-content: flex-end;
}